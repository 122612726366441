import React from "react";
import GolfServices from "../components/GolfServices"; 
import Footer from "../components/Footer"

export default function Home() {
  return (
    <>
      <GolfServices />
      <Footer />
      </>
  );
};
