import React from "react";
import MainServices from "../components/MainServices"; 
import Footer from "../components/Footer"

export default function Home() {
  return (
    <>
      <MainServices />
      <Footer />
      </>
  );
};
